.guideCardContainer {
    -webkit-box-shadow: 10px 10px 32px 0px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 10px 10px 32px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 10px 10px 32px 0px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-bottom: 50px;
    background-color: white;
    height: 100%;
}

.guide-card-container .guide-card-text {
    margin: 20px;
}

.imageBackground {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
}

.textdiv {
    padding: 40px;
    color: gray;
}


.guide-card-header {
    height: 100%;
    width: 100%;
}

.text{
    color:black;
}