.Impressum_impressum__3jMMF{
    color:black;
    background-color: white;
    padding:40px;
}
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid rgb(71, 24, 24);
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  @-webkit-keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
* {
  box-sizing: border-box;
}

html,
body,
.container {
  margin: 0;
  height: 100vh;
  color: white;
}

body {
  font-family: 'Montserrat', sans-serif;
}

p {
  font-size: 0.8em;
  font-weight: 200;
}


a {
  text-decoration: none;
  color: white;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-size: 1.4em;
}

h2 {
  font-size: 1.2em;
}

.header {
  background-color: #00334C;
  position: fixed;
  z-index: 1000;
  width: 100%;
  text-align: left;
  top: 0;
}

.shadow {
  box-shadow: 3px -4px 42px -17px rgba(0, 0, 0, 0.75);
}

.footer {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  background-color: #00334C;
  padding: 40px;
  color: white;
}
.praxis{
  width:80%;
  margin:auto;
}
.praxis ul{
  width:90%;
  margin-left:100px;
  text-align: left;
  margin:auto;
  padding-left: 0px;
}

.praxis li{
  margin-left:15%;
  font-size:0.8em;
}


@media(min-width:800px) {
  h1 {
    font-size: 1.8em;
    font-weight: 700;
  }

  h2 {
    font-size: 1.4em;
    font-weight: 600;
  }

  h3 {
    font-size: 1.3em;
    font-weight: 300;
  }

  p {
    font-size: 1em;
    font-weight: 200;

  }

  .heading h1 {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .heading h2 {
    font-weight: 200;
    font-size: 50px;
  }

  .praxis {
    width: 80%;
    margin: auto;
  }

  .praxis ul {
    width: 60%;
    margin-left: 100px;
    text-align: left;
    margin: auto;
  }

  .praxis li {
    margin-left: 15%;
    font-size:1em;
  }
}

/* Mobile settings */

header h1 {
  color: white;
}
/* NAVBAR mobile*/
#opener:hover {
  cursor: pointer;
}

#opener:active~.navbar-collapse {
  display: block;
}

#opener:hover .navbar-collapse {
  display: block;

}

.navbar {
  letter-spacing: 2px;
  background-color: #00334C;
  width: 100%;
  position: fixed;
  height: 55px;

}

.navbar a:hover {
  color: rgb(153, 142, 142);
}

.navbar-collapse {
  display: none;
}

.navbar a {
  text-decoration: none;
  color: white;
  font-weight: 100;
}

.navbar ul {
  list-style-type: none;
  padding-top: 40px;
  padding-left:15px;
}

.navbar li {
  height: 20px;
}

/* side menu mobile*/

.open-drawer {
  top:0;
  bottom: 0;
  left: 0;
  margin: 0;
  background-color: rgb(67, 83, 110);
  padding: 15px;
  height:100%;
  
  z-index: 2000;
  width: 100%;
  position:fixed;

  display: block;
  opacity: 0.97;
  
  -webkit-transform: translate3d(0, -120%, 0);
  
          transform: translate3d(0, -120%, 0);
  -webkit-transition: transform .15s ease-in-out;
  -webkit-transition: -webkit-transform .15s ease-in-out;
  transition: -webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out;
  transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.open-drawer-active {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: transform 1s ease-in-out;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;

}

.open-drawer li {
  text-align: left;
  margin-bottom: 20px;
  border-bottom: 1px;
}

.menu-icon {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}

.menu-open-icon {
  font-size: 30px;
  margin-top: 15px;
}

.menu-open-icon:hover {
  cursor: pointer;
}

.closeicon{
  display:true;
  padding:15px;
}

/*Desktop Navbar*/
@media(min-width:800px) {

  .closeicon{
    display: none;
  }
 
  .navbar {
    letter-spacing: 2px;
    width: 100%;
  }

  .navbar-collapse {
    display: block;
  }

  .navbar .menu-icon {
    display: none;
  }

  .navbar ul {
    list-style-type: none;
    padding-top: 0px;
  }

  .open-drawer {
    display: none;
  }

  .navbar-color {

    background-color: rgba(9, 21, 43, 0.911);
    -webkit-animation-name: fadeInOpacity;
            animation-name: fadeInOpacity;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }

  .navbar-transparent {
    background-color: #00334C88;
  }

  @-webkit-keyframes fadeInOpacity {
    0% {
      background-color: rgba(28, 32, 32, 0);
    }

    100% {
      background-color: rgba(10, 30, 65, 0.85);
    }
  }

  @keyframes fadeInOpacity {
    0% {
      background-color: rgba(28, 32, 32, 0);
    }

    100% {
      background-color: rgba(10, 30, 65, 0.85);
    }
  }

  @-webkit-keyframes fadeOutOpacity {
    0% {
      background-color: rgba(10, 30, 65, 0.85);
    }

    100% {
      background-color: rgba(28, 32, 32, 0);
    }
  }

  @keyframes fadeOutOpacity {
    0% {
      background-color: rgba(10, 30, 65, 0.85);
    }

    100% {
      background-color: rgba(28, 32, 32, 0);
    }
  }

  .navbar ul {
    align-content: right;
    margin-right: 80px;
  }

  .navbar li {
    display: inline;
    margin-right: 20px;

  }

  .navbar a:hover {
    color: rgb(153, 142, 142);
  }

  navbar-header {
    padding-top: 7px;
    padding-right: 7px;
  }
}

.TeamCard_guideCardContainer__35rKM {
    box-shadow: 10px 10px 32px 0px rgba(0, 0, 0, 0.24);
    text-align: left;
    margin-bottom: 50px;
    background-color: white;
    height: 100%;
}

.TeamCard_guide-card-container__1wqna .TeamCard_guide-card-text__6kKYO {
    margin: 20px;
}

.TeamCard_imageBackground__1qU1W {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
}

.TeamCard_textdiv__2325Q {
    padding: 40px;
    color: gray;
}


.TeamCard_guide-card-header__3EWVq {
    height: 100%;
    width: 100%;
}

.TeamCard_text__3CkJt{
    color:black;
}
.TeamGrid_background__21QVq{
    color: white;
    text-align: center;
    width: 80%;
    margin: auto;
    padding-bottom:50px;
}

.TeamGrid_guidegrid__39gq9{
    margin-top:40px;
}



  
 
  

  
    
  

.ServiceCard_cardContainer__1ds0y {
    position: relative;
    text-align: center;
    background-color: #1d241dCC;
    min-height: 320px;    
    display:flex;
    justify-content: center;
    align-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ServiceCard_textdiv__2WQGx {
    z-index: 200;
    font-size: 0.9em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ServiceCard_textdiv__2WQGx h2{
    font-size:1.4em;
    padding:10px;
    width:100%;
    background-color:#3c313178;
}

.ServiceCard_cardContainer__1ds0y:hover .ServiceCard_overlay__3_V-4{
    opacity:1;
    cursor: pointer;
  
}

.ServiceCard_cardContainer__1ds0y:hover .ServiceCard_textdiv__2WQGx{
   opacity:0;
   
    
}

.ServiceCard_overlay__3_V-4{
    -webkit-transition: .5s ease;
    transition: .5s ease;
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    z-index:100;
    background-color:rgba(14, 12, 12, 0.603);;
    color:white;
    opacity:0;
    padding:50px;
    display: flex;
    justify-content: center;
    font-size:0.9em;
    align-items: center;
}


.ServiceCard_image__3zQZb{
    width: 100%;
    height: auto;
}

.ServiceCard_imageOverlay__gX9KJ{
    position: absolute;
    top:0px;
    height:100%;
    background-color: rgba(19, 18, 18, 1);
}

/* mobile */
@media only screen and (max-width: 768px) {

    .ServiceCard_overlay__3_V-4{
        -webkit-transition: .5s ease;
        transition: .5s ease;
        position:absolute;
        top:0px;
        left:0px;
        height:100%;
        z-index:100;
        background-color:rgba(14, 12, 12, 0.603);;
        color:white;
        opacity:0;
        padding:10px;
        display: flex;
        justify-content: center;
        font-size: 0.6em;
        align-items: center;
    }

    .ServiceCard_textdiv__2WQGx h2{
        font-size:1.1em;
        padding:10px;
        position: absolute;
        width:100%;
        left:0;
        right:0;
        background-color:#3c313178;
    }
}
.ServiceGrid_background__3ULK6{
    color: white;
    text-align: center;
    width: 100%;
    padding-bottom:50px;
}

.ServiceGrid_heading__2kZ1k{
    width:80%;
    margin:auto;
    padding-bottom: 50px;
}

.ServiceGrid_guidegrid__3P-YF{
    margin-top:40px;
}



  
 
  

  
    
  

.Info_info__2YFsu{
    color:white;
    text-align: center;    
    padding-left:10%;
    padding-right:10%;
}



.Praxis_info__1ZA1V{
    color:white;
    text-align: center;    
}

.Praxis_info__1ZA1V ul{
    text-decoration: none;
    list-style-type: symbols();
}

.Praxis_info__1ZA1V li{
    font-weight: 100;
}




/* Slider wrapper*/

.HeaderCarousel_wrapper__2V1u- {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Slider */
.HeaderCarousel_slider__3f5m4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.HeaderCarousel_sliderContent__h4WP2 {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  z-index: 1000;
}

.HeaderCarousel_sliderPagination__3_A8P {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  
}

.HeaderCarousel_label__VeBxj {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 5px;
  border: solid 2px rgb(255, 255, 255);
  cursor: pointer;
}
.HeaderCarousel_title__LgIiE {
  font-size: 1rem;
  color: rgba(224, 224, 224, 0.5);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
}


.HeaderCarousel_infotext__1SAIo {
  color: white;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

.HeaderCarousel_subtext__ZnyVq{
  position: relative;
  top:20px;
  color: white;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 15px;
  
}

.HeaderCarousel_subsubtext__2kRoa{
  position: relative;
  top:20px;
  color: white;
  font-size: 1.1rem;
  font-weight: 100;
  line-height: 1.2;
  margin-top: 15px;
  
}



.HeaderCarousel_arrow__2Lg2S {
  font-size: 30px;
}

.HeaderCarousel_arrow__2Lg2S:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transform: translate(0, -20);
          transform: translate(0, -20);
  cursor: pointer;
}

.HeaderCarousel_sliderContentText__2dnJH {
  width:95%;
  text-align: center;
  
}


.HeaderCarousel_overlay__2y497{
  position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  background-color: black;
  opacity:0.5;
}


/* Responsive */
@media only screen and (max-width: 768px) {
  .HeaderCarousel_sliderContentText__2dnJH {
    text-align: center;
  }


  .HeaderCarousel_arrow__2Lg2S {
    font-size: 20px;
  }

  .HeaderCarousel_h2__3VTuO {
    font-size: 1.5rem;
  }

  .HeaderCarousel_h4__251F4 {
    font-size: 1.1rem;
  }

  .HeaderCarousel_sliderContent__h4WP2 {
    padding: 0 2%
  }

  .HeaderCarousel_navigation__3Q4Y1 {
    padding: 0 2%;
  }

  .HeaderCarousel_navigationLeft__37omg {
    display: none;
  }
}

.HeaderCarousel_input__3Y3ip{
  display:none;
}
