.background{
    color: white;
    text-align: center;
    width: 100%;
    padding-bottom:50px;
}

.heading{
    width:80%;
    margin:auto;
    padding-bottom: 50px;
}

.guidegrid{
    margin-top:40px;
}



  
 
  

  
    
  
