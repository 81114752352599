
/* Mobile settings */

header h1 {
  color: white;
}
/* NAVBAR mobile*/
#opener:hover {
  cursor: pointer;
}

#opener:active~.navbar-collapse {
  display: block;
}

#opener:hover .navbar-collapse {
  display: block;

}

.navbar {
  letter-spacing: 2px;
  background-color: #00334C;
  width: 100%;
  position: fixed;
  height: 55px;

}

.navbar a:hover {
  color: rgb(153, 142, 142);
}

.navbar-collapse {
  display: none;
}

.navbar a {
  text-decoration: none;
  color: white;
  font-weight: 100;
}

.navbar ul {
  list-style-type: none;
  padding-top: 40px;
  padding-left:15px;
}

.navbar li {
  height: 20px;
}

/* side menu mobile*/

.open-drawer {
  top:0;
  bottom: 0;
  left: 0;
  margin: 0;
  background-color: rgb(67, 83, 110);
  padding: 15px;
  height:100%;
  
  z-index: 2000;
  width: 100%;
  position:fixed;

  display: block;
  opacity: 0.97;
  
  transform: translate3d(0, -120%, 0);
  -webkit-transition: transform .15s ease-in-out;
  -moz-transition: transform .15s ease-in-out;
  -o-transition: transform .15s ease-in-out;
  transition: transform .15s ease-in-out;
}

.open-drawer-active {
  transform: translate3d(0, 0, 0);
  -webkit-transition: transform 1s ease-in-out;
  -moz-transition: transform 1s ease-in-out;
  -o-transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out;

}

.open-drawer li {
  text-align: left;
  margin-bottom: 20px;
  border-bottom: 1px;
}

.menu-icon {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}

.menu-open-icon {
  font-size: 30px;
  margin-top: 15px;
}

.menu-open-icon:hover {
  cursor: pointer;
}

.closeicon{
  display:true;
  padding:15px;
}

/*Desktop Navbar*/
@media(min-width:800px) {

  .closeicon{
    display: none;
  }
 
  .navbar {
    letter-spacing: 2px;
    width: 100%;
  }

  .navbar-collapse {
    display: block;
  }

  .navbar .menu-icon {
    display: none;
  }

  .navbar ul {
    list-style-type: none;
    padding-top: 0px;
  }

  .open-drawer {
    display: none;
  }

  .navbar-color {

    background-color: rgba(9, 21, 43, 0.911);
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }

  .navbar-transparent {
    background-color: #00334C88;
  }

  @keyframes fadeInOpacity {
    0% {
      background-color: rgba(28, 32, 32, 0);
    }

    100% {
      background-color: rgba(10, 30, 65, 0.85);
    }
  }

  @keyframes fadeOutOpacity {
    0% {
      background-color: rgba(10, 30, 65, 0.85);
    }

    100% {
      background-color: rgba(28, 32, 32, 0);
    }
  }

  .navbar ul {
    align-content: right;
    margin-right: 80px;
  }

  .navbar li {
    display: inline;
    margin-right: 20px;

  }

  .navbar a:hover {
    color: rgb(153, 142, 142);
  }

  navbar-header {
    padding-top: 7px;
    padding-right: 7px;
  }
}
