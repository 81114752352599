.cardContainer {
    position: relative;
    text-align: center;
    background-color: #1d241dCC;
    min-height: 320px;    
    display:flex;
    justify-content: center;
    align-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.textdiv {
    z-index: 200;
    font-size: 0.9em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textdiv h2{
    font-size:1.4em;
    padding:10px;
    width:100%;
    background-color:#3c313178;
}

.cardContainer:hover .overlay{
    opacity:1;
    cursor: pointer;
  
}

.cardContainer:hover .textdiv{
   opacity:0;
   
    
}

.overlay{
    transition: .5s ease;
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    z-index:100;
    background-color:rgba(14, 12, 12, 0.603);;
    color:white;
    opacity:0;
    padding:50px;
    display: flex;
    justify-content: center;
    font-size:0.9em;
    align-items: center;
}


.image{
    width: 100%;
    height: auto;
}

.imageOverlay{
    position: absolute;
    top:0px;
    height:100%;
    background-color: rgba(19, 18, 18, 1);
}

/* mobile */
@media only screen and (max-width: 768px) {

    .overlay{
        transition: .5s ease;
        position:absolute;
        top:0px;
        left:0px;
        height:100%;
        z-index:100;
        background-color:rgba(14, 12, 12, 0.603);;
        color:white;
        opacity:0;
        padding:10px;
        display: flex;
        justify-content: center;
        font-size: 0.6em;
        align-items: center;
    }

    .textdiv h2{
        font-size:1.1em;
        padding:10px;
        position: absolute;
        width:100%;
        left:0;
        right:0;
        background-color:#3c313178;
    }
}