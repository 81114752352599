/* Slider wrapper*/

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Slider */
.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.sliderContent {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  z-index: 1000;
}

.sliderPagination {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  
}

.label {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(255, 255, 255, 0.5);
  margin: 0 5px;
  border: solid 2px rgb(255, 255, 255);
  cursor: pointer;
}
.title {
  font-size: 1rem;
  color: rgba(224, 224, 224, 0.5);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
}


.infotext {
  color: white;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

.subtext{
  position: relative;
  top:20px;
  color: white;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 15px;
  
}

.subsubtext{
  position: relative;
  top:20px;
  color: white;
  font-size: 1.1rem;
  font-weight: 100;
  line-height: 1.2;
  margin-top: 15px;
  
}



.arrow {
  font-size: 30px;
}

.arrow:hover {
  transform: scale(1.2);
  transform: translate(0, -20);
  cursor: pointer;
}

.sliderContentText {
  width:95%;
  text-align: center;
  
}


.overlay{
  position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  background-color: black;
  opacity:0.5;
}


/* Responsive */
@media only screen and (max-width: 768px) {
  .sliderContentText {
    text-align: center;
  }


  .arrow {
    font-size: 20px;
  }

  .h2 {
    font-size: 1.5rem;
  }

  .h4 {
    font-size: 1.1rem;
  }

  .sliderContent {
    padding: 0 2%
  }

  .navigation {
    padding: 0 2%;
  }

  .navigationLeft {
    display: none;
  }
}

.input{
  display:none;
}