.info{
    color:white;
    text-align: center;    
}

.info ul{
    text-decoration: none;
    list-style-type: symbols();
}

.info li{
    font-weight: 100;
}



